#COLOR {
    color: #771835;
}

$primary-color: #8a1814;
$primary-color-light-background: #f8e8d7; 
$primary-color-highlighted: #ca2009;
$primary-color-lightened: #e29390;

$header-height: 150px;
$page-width: 960px;
$page-margin: 130px;

$phone: "only screen and (max-width : 400px)";

body {
	background-color: $primary-color;
    font-family: 'Libre Franklin', sans-serif;
    font-weight: 200;
    scroll-behavior: smooth;
}

#page {
    max-width: $page-width;
    margin: 0 auto 0 auto;
    background-color: #FFFFFF;
    // padding-top: $header-height;
    padding-bottom: 40px;
}

.centered {
    text-align: center;
}

.container {
    padding-top: 20px; 
    max-width: $page-width - ( 2 * $page-margin );
}

#header {
    max-width: $page-width;
    margin: 0 auto;
}

#header .container,
#footer .container {
    padding-top: 0;
}

img {
    display: block;
    max-width: 100%;
}

a {
    color: $primary-color;
    text-decoration: none;
    border-bottom: 1px dotted $primary-color;

    &:hover {
        color: $primary-color-highlighted;
    }
}

.logo {
	display: inline-block;
	vertical-align: middle;
}
.logotype {
	display: inline-block;
	vertical-align: middle;
	margin-left: 5px;
	font-weight: 700;
}

small {
	font-size: 60%;
}

.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"],
h1,h2,h3,h4,h5,h6,
#nav {
	letter-spacing: 2px;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 700;
}

h1,h2,h3,h4,h5,h6,
#nav {
	font-weight: 300;
	padding: 10px 0;
}

.button.button-primary,
button.button-primary,
input[type="submit"].button-primary,
input[type="reset"].button-primary,
input[type="button"].button-primary {
	color: #FFF;
	background-color: $primary-color;
	border-color: $primary-color; 
    
    &:hover, &:focus {
        background-color: $primary-color-highlighted;
        border-color: $primary-color-highlighted; 
    }
}

strong {
	font-weight: 700;
}

svg #Eric {
    fill-opacity: 0;
    stroke : rgba(0, 0, 0, 1.0);
}

#nav {
    
    height: $header-height;
    
    ul {
        list-style: none;
        display: inline-block;
        margin: 0;
    }
    
    li {
        display: inline-block;
    }
    
    .logo svg {
        padding-top: 20px;
	    width: 140px;
    }
    
    svg #Eric {
        stroke : $primary-color;
        
        &:hover {
            stroke : $primary-color-highlighted;
        }
    }
    
    a {
        color: #000000;
        border: none;
        
        &:hover {
	        color: $primary-color-highlighted;
            
            svg #Strings path {
                stroke: $primary-color-highlighted;
            }
            
            svg #Sticks path {
                fill: $primary-color-highlighted;
            }

        }
    }
}

.callout {
    float: right;
    width: 50%;
    margin: 5px 0 10px 20px;
}

.illustration {
    background-color: $primary-color;
    
    &.u-full-width.tall-image {
        display: none;
        
        @media #{$phone} {
            display: block;
        }
    }
    
    &.callout.tall-image {
        max-width: 33%;
        @media #{$phone} {
            display: none;
        }
    }
}

.software-blurb img,
.client-blurb img,
.illustration img {
	width: 100%;
}

.software-blurb img {
    max-width: 100px;
}

.client-blurb img {
	margin-bottom: 10px;
}

.headline {
    margin: 10px 0 20px;
    
    h1 {
        display: inline;
        font-size: 150%;
        font-weight: 700;
        color: $primary-color;
    }
    
    h1:after {
        display: inline;
        content: " ";
    }
    
    .meta {
        display: inline-block;
        font-weight: 100;
        font-size: 12px;
    }
}

.posts {
    .post {
        .date {
            display: inline-block;
            vertical-align: top;
            width: 6em;
        }
        .title {
            display: inline-block;
            vertical-align: top;
            width: calc(100% - 6em);
        }
    }
}

#header {
    // position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    text-align: center;
    background: rgba(255, 255, 255, 1);
}

#main {
    &.small-images {
        img {
            display: inline-block;
            margin-left: 5px;
            margin-right: 5px;
            max-height: 100px;
            border: none;
        }
    }
}

#footer {
	padding-top: 20px;
	background-color: $primary-color;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: 100;
    
    strong {
        text-transform: uppercase;
        letter-spacing: 2px;
    }
    
    ul {
        list-style: none;
        display: inline-block;
        margin: 0;
    }
    
    li {
        display: inline-block;
        
        &:after {
            padding: 0 5px;
            content: "/"
        }
        
        &:last-child:after {
            padding: 0;
            content: ""
        }
    }
    
    a {
        color: #FFFFFF;
        border-color: #FFFFFF;
        
        &:hover {
            color: $primary-color-lightened;
            border-color: $primary-color-lightened;
        }
    }
    
    .logo {
        display: inline-block;
        vertical-align: top;
        svg {
            width: 60px;
        }
    }
    
    .address {
        display: inline-block;
        vertical-align: top;
        margin-left: 20px;
    }
    
    svg #Eric {
        stroke : $primary-color-highlighted;
        
        &:hover {
            stroke : $primary-color-lightened;            
        }
    }
    
}

.no-decoration a,
a.no-decoration {
    border: none;
}

#google-form-success,
#google-form-error {
    display: none;
}

/* Code highlighting */

.highlight .hll { background-color: #ffffcc }
.highlight .c { color: #999988; font-style: italic } /* Comment */
.highlight .err { color: #a61717; background-color: #e3d2d2 } /* Error */
.highlight .k { color: #000000; font-weight: bold } /* Keyword */
.highlight .o { color: #000000; font-weight: bold } /* Operator */
.highlight .cm { color: #999988; font-style: italic } /* Comment.Multiline */
.highlight .cp { color: #999999; font-weight: bold; font-style: italic } /* Comment.Preproc */
.highlight .c1 { color: #999988; font-style: italic } /* Comment.Single */
.highlight .cs { color: #999999; font-weight: bold; font-style: italic } /* Comment.Special */
.highlight .gd { color: #000000; background-color: #ffdddd } /* Generic.Deleted */
.highlight .ge { color: #000000; font-style: italic } /* Generic.Emph */
.highlight .gr { color: #aa0000 } /* Generic.Error */
.highlight .gh { color: #999999 } /* Generic.Heading */
.highlight .gi { color: #000000; background-color: #ddffdd } /* Generic.Inserted */
.highlight .go { color: #888888 } /* Generic.Output */
.highlight .gp { color: #555555 } /* Generic.Prompt */
.highlight .gs { font-weight: bold } /* Generic.Strong */
.highlight .gu { color: #aaaaaa } /* Generic.Subheading */
.highlight .gt { color: #aa0000 } /* Generic.Traceback */
.highlight .kc { color: #000000; font-weight: bold } /* Keyword.Constant */
.highlight .kd { color: #000000; font-weight: bold } /* Keyword.Declaration */
.highlight .kn { color: #000000; font-weight: bold } /* Keyword.Namespace */
.highlight .kp { color: #000000; font-weight: bold } /* Keyword.Pseudo */
.highlight .kr { color: #000000; font-weight: bold } /* Keyword.Reserved */
.highlight .kt { color: #445588; font-weight: bold } /* Keyword.Type */
.highlight .m { color: #009999 } /* Literal.Number */
.highlight .s { color: #d01040 } /* Literal.String */
.highlight .na { color: #008080 } /* Name.Attribute */
.highlight .nb { color: #0086B3 } /* Name.Builtin */
.highlight .nc { color: #445588; font-weight: bold } /* Name.Class */
.highlight .no { color: #008080 } /* Name.Constant */
.highlight .nd { color: #3c5d5d; font-weight: bold } /* Name.Decorator */
.highlight .ni { color: #800080 } /* Name.Entity */
.highlight .ne { color: #990000; font-weight: bold } /* Name.Exception */
.highlight .nf { color: #990000; font-weight: bold } /* Name.Function */
.highlight .nl { color: #990000; font-weight: bold } /* Name.Label */
.highlight .nn { color: #555555 } /* Name.Namespace */
.highlight .nt { color: #000080 } /* Name.Tag */
.highlight .nv { color: #008080 } /* Name.Variable */
.highlight .ow { color: #000000; font-weight: bold } /* Operator.Word */
.highlight .w { color: #bbbbbb } /* Text.Whitespace */
.highlight .mf { color: #009999 } /* Literal.Number.Float */
.highlight .mh { color: #009999 } /* Literal.Number.Hex */
.highlight .mi { color: #009999 } /* Literal.Number.Integer */
.highlight .mo { color: #009999 } /* Literal.Number.Oct */
.highlight .sb { color: #d01040 } /* Literal.String.Backtick */
.highlight .sc { color: #d01040 } /* Literal.String.Char */
.highlight .sd { color: #d01040 } /* Literal.String.Doc */
.highlight .s2 { color: #d01040 } /* Literal.String.Double */
.highlight .se { color: #d01040 } /* Literal.String.Escape */
.highlight .sh { color: #d01040 } /* Literal.String.Heredoc */
.highlight .si { color: #d01040 } /* Literal.String.Interpol */
.highlight .sx { color: #d01040 } /* Literal.String.Other */
.highlight .sr { color: #009926 } /* Literal.String.Regex */
.highlight .s1 { color: #d01040 } /* Literal.String.Single */
.highlight .ss { color: #990073 } /* Literal.String.Symbol */
.highlight .bp { color: #999999 } /* Name.Builtin.Pseudo */
.highlight .vc { color: #008080 } /* Name.Variable.Class */
.highlight .vg { color: #008080 } /* Name.Variable.Global */
.highlight .vi { color: #008080 } /* Name.Variable.Instance */
.highlight .il { color: #009999 } /* Literal.Number.Integer.Long */

code {
    overflow: auto;
}

/* skeleton.css adjustments */
input:last-of-type,
textarea:last-of-type,
select:last-of-type,
fieldset:last-of-type {
    margin-bottom: 0;
    margin-top: 1.5rem;
}
pre,
blockquote,
dl,
figure,
table,
p,
ul,
ol,
form {
    margin-bottom: 2.0rem; 
}
ul {
    list-style: circle outside;
    margin-left: 1.5em;
}
ol {
    list-style: decimal outside;
    margin-left: 1.5em;
}

/* Disqus Styles */
#disqus_thread {
    display: none;
    background-color: $primary-color;
    color: white;
    padding: 20px;
    a {
        color: $primary-color-lightened;
    }
}